$(document).on('ready turbolinks:load', function() {
	$("input.currency-input").keyup(function(event){
	  // skip for arrow keys
	  if(event.which >= 37 && event.which <= 40){
	    event.preventDefault();
	  }

	  var $this = $(this);
	  var num   = $this.val().toString().split(".")[0];
	  var num2  = num.replace(/,/gi, "");
		var num3  = num2.split(/(?=(?:\d{3})+$)/).join(",");
	  
	  console.log(num3);
	  
	  // the following line has been simplified. Revision history contains original.
	  $this.val(num3);
	}).keyup();

	$('.toggle-trigger').click(function(event){
    event.preventDefault();
    target = $(this).data('toggleable');

    $(target).hide();
  });

  $('.toggle-check').change(function(){
    var status = $(this).prop('checked'),
        target = $(this).data('toggleable');

    if (status == false) {
      $(target).removeClass('d-none');
    } else {
      $(target).addClass('d-none');
    }
  }).change();

  $(".alerts").delay(5000).slideUp(500);
});

document.addEventListener('DOMContentLoaded', function() {
  const entitySelect = document.getElementById('entity-select');

  if (entitySelect) {
    entitySelect.addEventListener('change', function() {
      const entityId = this.value;
      if (entityId) {
        window.location.href = `/entities/${entityId}`;
      }
    });
  }

  const vendorSelect = document.getElementById('vendor-select');

  if (vendorSelect) {
    vendorSelect.addEventListener('change', function() {
      const vendorId = this.value;
      if (vendorId) {
        window.location.href = `/vendors/${vendorId}`;
      }
    });
  }
});
